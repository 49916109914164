import React, { Component,Suspense } from 'react';
import $ from "jquery";
import PopularItems from "../groceryDefault/popularItemsNew"
import FilterSectionDiv from './filterSection';
import ItemListingData from './itemListingData';
import { getData, pageHitAPICall } from '../services';
import { Helmet } from "react-helmet";
import Loader from "../headerComponents/loader"
import triangle from '../images/icons/triangle.png'
import lence from '../images/icons/lence.png'
import arowdown from '../images/lokal/arow-down.png'
import { elasticLink, elasticHostURL } from "../config";
let MainHeader = React.lazy(() => import("../" + localStorage.getItem("themeName") + "/header"));
let MainFooter = React.lazy(() => import("../" + localStorage.getItem("themeName") + "/footer"));
const AWS = require('aws-sdk');
const elasticsearch = require("elasticsearch");
AWS.config.region = 'us-west-1';
export default class listing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sortActive: false,
      itemDetails: [],
      storeResults: [],
      imagePath: '',
      currencySymbol: '',
      cityId: '',
      category: [],
      bannerDetails: [],
      isShowFilterSection: false,
      divPagination: '0',
      rowCount: '',
      isNoDataFound: false,
      isLoading: '0',
      pageNumber: 1,
      s3AccessKey: '',
      s3SecretKey: '',
      isData: '1',
      itemOffset: ''
    };
    this.sortClick = this.sortClick.bind(this);
    this.onFilter = this.onFilter.bind(this);
    this.sortListClick = this.sortListClick.bind(this);
    this.itemListing = this.itemListing.bind(this);
    this.itemLoad = this.itemLoad.bind(this);
  }
  itemLoad() {
    let sortBy = (window.sessionStorage.getItem('sortBy') === null ? '' : window.sessionStorage.getItem('sortBy'));
    let category1Id = (window.sessionStorage.getItem('category1Id') === null ? '' : window.sessionStorage.getItem('category1Id'));
    let category2Id = (window.sessionStorage.getItem('category2Id') === null ? '' : window.sessionStorage.getItem('category2Id'));
    let category3Id = (window.sessionStorage.getItem('category3Id') === null ? '' : window.sessionStorage.getItem('category3Id'));
    let brandIds = (window.sessionStorage.getItem('brandIds') === null ? '' : window.sessionStorage.getItem('brandIds'));
    let minPrice = (window.sessionStorage.getItem('minPrice') === null ? '' : window.sessionStorage.getItem('minPrice'));
    let maxPrice = (window.sessionStorage.getItem('maxPrice') === null ? '' : window.sessionStorage.getItem('maxPrice'));
    let offset = (window.sessionStorage.getItem('offset') === null ? 0 : window.sessionStorage.getItem('offset'));
    let max = (window.sessionStorage.getItem('max') === null ? 40 : window.sessionStorage.getItem('max'));
    let searchKeyword = (((window.sessionStorage.getItem('searchKeyword') !== '' && window.sessionStorage.getItem('searchKeyword') !== null) || window.sessionStorage.getItem('searchKeyword') !== 'null') ? window.sessionStorage.getItem('searchKeyword') : '');
    var pageNumber = this.state.pageNumber
    this.itemListing(searchKeyword, category1Id, category2Id, category3Id, offset, max, pageNumber, brandIds, sortBy, minPrice, maxPrice)
  }
  triggerChildAlert() {
    if (this.state.itemDetails.length > 0) {
      this.refs.filterSection.showAlert();
    }
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.categoryLevel1Id === this.props.categoryLevel1Id) {
      window.location.reload();
    }
  }
  async itemListing(txtUserSearch, category1, category2, category3, offset, max, pageNumber, brandIds, sortBy, minPrice, maxPrice) {
    // debugger
    offset = (offset === null || offset === "null") ? 0 : offset
    let themeId = localStorage.getItem('themeId')
    brandIds = (brandIds === '' ? '*' : brandIds.replace(/ /g, "-"));
    const client = new elasticsearch.Client({
      host: elasticHostURL(),
      amazonES: {
        region: 'us-west-1',
        // credentials: new AWS.Credentials(this.state.s3AccessKey, this.state.s3SecretKey)
      }
    });
    let storeId = window.sessionStorage.getItem('storeId');
    var filters = [];
    var fields = ['store_id', 'category_level1_id', 'category_level2_id', 'category_level3_id', 'sold_out', 'brand_id'];
    var paramsVal = [storeId, category1, category2, category3, '0', brandIds]
    for (var k = 0; k < fields.length; k++) {
      if (paramsVal[k] !== '*' && paramsVal[k] !== '') {
        if (fields[k] === 'brand_id') {
          var brandSplit = paramsVal[k].toString().split('-')
          var termq = { terms: {} };
          termq.terms[fields[k]] = brandSplit;
          filters.push(termq);
        }
        else {
          var termq1 = { term: {} };
          termq1.term[fields[k]] = paramsVal[k];
          filters.push(termq1);
        }
      }
    }
    if (minPrice !== '*' && minPrice !== '') {
      filters.push({ range: { price_min: { gte: minPrice, lte: maxPrice } } });
      filters.push({ range: { price_max: { gte: minPrice, lte: maxPrice } } });
    }
    var sortByFilter
    if (sortBy === 'offer') {
      sortByFilter = [{ is_offer: "desc" }, { sold_out: "asc" }, { item_ranking: "asc" }, { "seller_item_name.keyword": "asc" }]
    }
    else if (sortBy === 'name') {
      sortByFilter = [{ sold_out: "asc" }, { "seller_item_name.keyword": "asc" }, { item_ranking: "asc" }]
    }
    else if (sortBy === 'asc' || sortBy === 'desc') {
      sortByFilter = [{ sold_out: "asc" }, { "price_min": sortBy }]
    }
    else {
      sortByFilter = [{ sold_out: "asc" }]
    }
    var popularItemData;
    var totalCount
    if (txtUserSearch !== '*' && txtUserSearch !== '') {
      const body = await client.search({
        index: elasticLink() + '-popular-items',
        from: offset, size: max,
        body: {
          query:
          {
            bool: {
              "must": {
                "multi_match": {
                  "query": txtUserSearch,
                  "type": "phrase_prefix",
                  "fields": ["search_item_name", "category_group_name"]
                }
              },
              filter:
              {
                bool:
                {
                  must: filters
                }
              }
            }
          },
          sort: sortByFilter
        }
      })
      popularItemData = body.hits.hits
      totalCount = body.hits.total.value
    }
    else {
      const body = await client.search({
        index: elasticLink() + '-popular-items',
        from: offset, size: max,
        body: {
          query:
          {
            bool: {
              filter:
              {
                bool:
                {
                  must: filters
                }
              }
            }
          },
          sort: sortByFilter
        }
      })
      popularItemData = body.hits.hits
      totalCount = body.hits.total.value
    }
    var selectedProduct = JSON.parse(localStorage.getItem('selectedCartProduct'))
    var finalDataSetNew = [];
    if (popularItemData.length > 0) {
      var finalDataSet = [];
      for (var i = 0; i < popularItemData.length; i++) {
        var finalDataSetSub = [];
        finalDataSet[i] = {
          initialCount: 0,
          rowcount: popularItemData[i]._source.rowcount,
          group_count: popularItemData[i]._source.group_count,
          city_id: popularItemData[i]._source.city_id,
          store_id: popularItemData[i]._source.store_id,
          brand_name: popularItemData[i]._source.brand_name,
          category_level1_id: popularItemData[i]._source.category_level1_id,
          category_level2_id: popularItemData[i]._source.category_level2_id,
          category_level3_id: popularItemData[i]._source.category_level3_id,
          brand_id: popularItemData[i]._source.brand_id,
          total_rank: popularItemData[i]._source.item_ranking,
          is_loose_item: popularItemData[i]._source.is_loose_item,
          denomination: popularItemData[i]._source.denomination,
          variation: popularItemData[i]._source.variation,
          hasContactUsWithoutPrice: popularItemData[i]._source.hascontactuswithoutprice,
          hasBuyOption: popularItemData[i]._source.hasbuyoption,
          storeButtonName:
            popularItemData[i]._source.storebuttonname === "null"
              ? ""
              : popularItemData[i]._source.storebuttonname,
          store_item_stock_ids_with_seperator: popularItemData[i]._source.store_item_stock_ids,
          item_ids_with_seperator: popularItemData[i]._source.item_ids,
          item_prices_with_seperator: popularItemData[i]._source.item_prices,
          pack_ids_with_seperator: popularItemData[i]._source.pack_ids,
          mrps_with_seperator: popularItemData[i]._source.mrps,
          logoPath_with_seperator: popularItemData[i]._source.logo_paths,
          stock_quantities_with_seperator:
            popularItemData[i]._source.stock_quantities,
          is_swap_items_with_seperator: popularItemData[i]._source.is_swap_items,
          logo_path_thumbs_with_seperator:
            popularItemData[i]._source.logo_path_thumbs,
          unit_with_seperator: popularItemData[i]._source.unit,
          is_offers_with_seperator: popularItemData[i]._source.is_offers,
          offer_text_with_seperator: popularItemData[i]._source.offer_text
        };
        if (popularItemData[i]._source.group_count > 1) {
          finalDataSet[i].is_brand_item = true;
          var itemIdsOld = popularItemData[i]._source.item_ids
            .replace(/\|/g, ",")
            .split(",");
          var group_count;
          for (var j = 0; j < popularItemData[i]._source.group_count; j++) {
            var isItemAdded = false;
            var index = -1;
            for (var k = 0; k < finalDataSetSub.length; k++) {
              if (typeof finalDataSetSub[k] != 'undefined' && finalDataSetSub[k] != null && finalDataSetSub[k] != '') {
                if (itemIdsOld[j] == finalDataSetSub[k].item_ids) {
                  isItemAdded = true;
                  index = k;
                  break;
                }
              }
            }
            if (isItemAdded === false) {
              group_count++;
              finalDataSetSub[j] = {
                unit: popularItemData[i]._source.unit
                  .replace(/\|/g, ",")
                  .split(",")[j],
                store_item_stock_ids: popularItemData[
                  i
                ]._source.store_item_stock_ids
                  .replace(/\|/g, ",")
                  .split(",")[j],
                unit_type: popularItemData[i]._source.unit_type
                  .replace(/\|/g, ",")
                  .split(",")[j],
                item_ids: popularItemData[i]._source.item_ids
                  .replace(/\|/g, ",")
                  .split(",")[j],
                item_names: popularItemData[i]._source.item_names
                  .replace(/\|/g, ",")
                  .split(",")[j],
                is_offers: popularItemData[i]._source.is_offers
                  .replace(/\|/g, ",")
                  .split(",")[j],
                offer_text: popularItemData[i]._source.offer_text
                  .replace(/\|/g, ",")
                  .split(",")[j],
                is_swap_items: popularItemData[i]._source.is_swap_items
                  .replace(/\|/g, ",")
                  .split(",")[j],
                stock_quantities: popularItemData[i]._source.stock_quantities
                  .replace(/\|/g, ",")
                  .split(",")[j],
                pack_ids: popularItemData[i]._source.pack_ids
                  .replace(/\|/g, ",")
                  .split(",")[j],
                pack_names: popularItemData[i]._source.pack_names
                  .replace(/\|/g, ",")
                  .split(",")[j],
                pack_names_without_unit: popularItemData[i]._source.pack_names
                  .replace(/\|/g, ",")
                  .split(",")[j],
                logoPath: popularItemData[i]._source.logo_paths
                  .replace(/\|/g, ",")
                  .split(",")[j],
                logo_path_thumbs: popularItemData[i]._source.logo_path_thumbs
                  .replace(/\|/g, ",")
                  .split(",")[j],
                slug: popularItemData[i]._source.slug
                  .replace(/\|/g, ",")
                  .split(",")[j],
                description: ""
              };
              if (popularItemData[i]._source.displayitemprice === true) {
                if (
                  popularItemData[i]._source.item_prices
                    .replace(/\|/g, ",")
                    .split(",")[j] ==
                  popularItemData[i]._source.mrps.replace(/\|/g, ",").split(",")[j]
                ) {
                  finalDataSetSub[j].is_display_item_price = false;
                  finalDataSetSub[j].mrps = popularItemData[i]._source.mrps
                    .replace(/\|/g, ",")
                    .split(",")[j];
                  finalDataSetSub[j].item_prices = popularItemData[i]._source.mrps
                    .replace(/\|/g, ",")
                    .split(",")[j];
                } else {
                  finalDataSetSub[j].is_display_item_price = true;
                  finalDataSetSub[j].mrps = popularItemData[i]._source.mrps
                    .replace(/\|/g, ",")
                    .split(",")[j];
                  finalDataSetSub[j].item_prices = popularItemData[
                    i
                  ]._source.item_prices
                    .replace(/\|/g, ",")
                    .split(",")[j];
                }
              } else {
                finalDataSetSub[j].is_display_item_price = false;
                finalDataSetSub[j].mrps = popularItemData[i]._source.mrps
                  .replace(/\|/g, ",")
                  .split(",")[j];
                finalDataSetSub[j].item_prices = popularItemData[i]._source.mrps
                  .replace(/\|/g, ",")
                  .split(",")[j];
              }
            } else {
              if (
                finalDataSetSub[index].item_prices <
                popularItemData[i]._source.item_prices
                  .replace(/\|/g, ",")
                  .split(",")[j]
              ) {
                finalDataSetSub[index].unit = popularItemData[i]._source.unit
                  .replace(/\|/g, ",")
                  .split(",")[j];
                finalDataSetSub[
                  index
                ].store_item_stock_ids = popularItemData[
                  i
                ]._source.store_item_stock_ids
                  .replace(/\|/g, ",")
                  .split(",")[j];
                finalDataSetSub[index].unit_type = popularItemData[
                  i
                ]._source.unit_type
                  .replace(/\|/g, ",")
                  .split(",")[j];
                finalDataSetSub[index].item_ids = popularItemData[
                  i
                ]._source.item_ids
                  .replace(/\|/g, ",")
                  .split(",")[j];
                finalDataSetSub[index].item_names = popularItemData[
                  i
                ]._source.item_names
                  .replace(/\|/g, ",")
                  .split(",")[j];
                finalDataSetSub[index].is_offers = popularItemData[
                  i
                ]._source.is_offers
                  .replace(/\|/g, ",")
                  .split(",")[j];
                finalDataSetSub[index].offer_text = popularItemData[
                  i
                ]._source.offer_text
                  .replace(/\|/g, ",")
                  .split(",")[j];
                finalDataSetSub[index].is_swap_items = popularItemData[
                  i
                ]._source.is_swap_items
                  .replace(/\|/g, ",")
                  .split(",")[j];
                finalDataSetSub[index].stock_quantities = popularItemData[
                  i
                ]._source.stock_quantities
                  .replace(/\|/g, ",")
                  .split(",")[j];
                finalDataSetSub[index].pack_ids = popularItemData[
                  i
                ]._source.pack_ids
                  .replace(/\|/g, ",")
                  .split(",")[j];
                finalDataSetSub[index].pack_names = popularItemData[
                  i
                ]._source.pack_names
                  .replace(/\|/g, ",")
                  .split(",")[j];
                finalDataSetSub[
                  index
                ].pack_names_without_unit = popularItemData[i]._source.pack_names
                  .replace(/\|/g, ",")
                  .split(",")[j];
                finalDataSetSub[index].logoPath = popularItemData[
                  i
                ]._source.logo_paths
                  .replace(/\|/g, ",")
                  .split(",")[j];
                finalDataSetSub[index].logo_path_thumbs = popularItemData[
                  i
                ]._source.logo_path_thumbs
                  .replace(/\|/g, ",")
                  .split(",")[j];
                finalDataSetSub[index].slug = popularItemData[i]._source.slug
                  .replace(/\|/g, ",")
                  .split(",")[j];
                finalDataSetSub[index].description = "";
                if (popularItemData[i]._source.displayitemprice === true) {
                  if (
                    popularItemData[i]._source.item_prices
                      .replace(/\|/g, ",")
                      .split(",")[j] ==
                    popularItemData[i]._source.mrps.replace(/\|/g, ",").split(",")[
                    j
                    ]
                  ) {
                    finalDataSetSub[index].is_display_item_price = false;
                    finalDataSetSub[index].mrps = popularItemData[i]._source.mrps
                      .replace(/\|/g, ",")
                      .split(",")[j];
                    finalDataSetSub[index].item_prices = popularItemData[
                      i
                    ]._source.mrps
                      .replace(/\|/g, ",")
                      .split(",")[j];
                  } else {
                    finalDataSetSub[index].is_display_item_price = true;
                    finalDataSetSub[index].mrps = popularItemData[i]._source.mrps
                      .replace(/\|/g, ",")
                      .split(",")[j];
                    finalDataSetSub[index].item_prices = popularItemData[
                      i
                    ]._source.item_prices
                      .replace(/\|/g, ",")
                      .split(",")[j];
                  }
                } else {
                  finalDataSetSub[index].is_display_item_price = false;
                  finalDataSetSub[index].mrps = popularItemData[i]._source.mrps
                    .replace(/\|/g, ",")
                    .split(",")[j];
                  finalDataSetSub[index].item_prices = popularItemData[
                    i
                  ]._source.mrps
                    .replace(/\|/g, ",")
                    .split(",")[j];
                }
              }
            }
            finalDataSet[i].group_count = group_count;
          }
        } else {
          finalDataSet[i].is_brand_item = false;
          if (popularItemData[i]._source.is_loose_item === true) {
            var availIn = "";
            var variation = popularItemData[i]._source.variation;
            for (var v = 1; v <= variation; v++) {
              if (availIn == "") {
                availIn = (v * popularItemData[i]._source.denomination).toString();
              } else {
                availIn =
                  availIn +
                  "," +
                  (v * popularItemData[i]._source.denomination).toString();
              }
            }
            var availInSplit = availIn.split(",");

            for (var a = 0; a < availInSplit.length; a++) {
              finalDataSetSub[a] = {};
              var packPrice =
                popularItemData[i]._source.item_prices !== null && popularItemData[i]._source.item_prices !== 'null' && popularItemData[i]._source.item_prices !== '0' &&
                  popularItemData[i]._source.item_prices !== 0
                  ? (popularItemData[i]._source.item_prices /
                    popularItemData[i]._source.unit) *
                  availInSplit[a]
                  : "-";
              var looseMrp =
                popularItemData[i]._source.mrps !== null && popularItemData[i]._source.mrps !== 'null' && popularItemData[i]._source.mrps !== '0' &&
                  popularItemData[i]._source.mrps !== 0
                  ? (popularItemData[i]._source.mrps /
                    popularItemData[i]._source.unit) *
                  availInSplit[a]
                  : "-";

              if (
                availInSplit[a] == "1000" &&
                popularItemData[i]._source.unit_type.toLowerCase() === "gm"
              ) {
                finalDataSetSub[a].pack_names = "1 Kg";
                finalDataSetSub[a].pack_names_without_unit = "1000";
              } else if (
                availInSplit[a] == "1000" &&
                popularItemData[i]._source.unit_type.toLowerCase() === "ml"
              ) {
                finalDataSetSub[a].pack_names = "1 Ltr";
                finalDataSetSub[a].pack_names_without_unit = "1000";
              } else {
                finalDataSetSub[a].pack_names =
                  availInSplit[a] + " " + popularItemData[i]._source.unit_type;
                finalDataSetSub[a].pack_names_without_unit = availInSplit[a];
              }
              finalDataSetSub[a].unit = popularItemData[i]._source.unit;
              finalDataSetSub[a].store_item_stock_ids =
                popularItemData[i]._source.store_item_stock_ids;
              finalDataSetSub[a].unit_type = popularItemData[i]._source.unit_type;
              finalDataSetSub[a].item_ids = popularItemData[i]._source.item_ids;
              finalDataSetSub[a].item_names =
                popularItemData[i]._source.item_names;
              finalDataSetSub[a].is_offers = popularItemData[i]._source.is_offers;
              finalDataSetSub[a].offer_text =
                popularItemData[i]._source.offer_text;
              finalDataSetSub[a].is_swap_items =
                popularItemData[i]._source.is_swap_items;
              finalDataSetSub[a].stock_quantities =
                popularItemData[i]._source.stock_quantities;
              finalDataSetSub[a].pack_ids = popularItemData[i]._source.pack_ids;
              finalDataSetSub[a].logoPath = popularItemData[i]._source.logo_paths;
              finalDataSetSub[a].logo_path_thumbs =
                popularItemData[i]._source.logo_path_thumbs;
              finalDataSetSub[a].slug = popularItemData[i]._source.slug;
              finalDataSetSub[a].description = "";
              if (popularItemData[i]._source.displayitemprice === true) {
                if (packPrice.toString() === looseMrp.toString()) {
                  finalDataSetSub[a].is_display_item_price = false;
                  finalDataSetSub[a].mrps = looseMrp;
                  finalDataSetSub[a].item_prices = looseMrp;
                } else {
                  finalDataSetSub[a].is_display_item_price = true;
                  finalDataSetSub[a].mrps = looseMrp;
                  finalDataSetSub[a].item_prices = packPrice;
                }
              } else {
                finalDataSetSub[a].is_display_item_price = false;
                finalDataSetSub[a].mrps = looseMrp;
                finalDataSetSub[a].item_prices = looseMrp;
              }
            }
          } else {
            var j = 0;
            finalDataSetSub[j] = {};
            finalDataSetSub[j].unit = popularItemData[i]._source.unit;
            finalDataSetSub[j].store_item_stock_ids =
              popularItemData[i]._source.store_item_stock_ids;
            finalDataSetSub[j].unit_type = popularItemData[i]._source.unit_type;
            finalDataSetSub[j].item_ids = popularItemData[i]._source.item_ids;
            finalDataSetSub[j].item_names = popularItemData[i]._source.item_names;
            finalDataSetSub[j].is_offers = popularItemData[i]._source.is_offers;
            finalDataSetSub[j].offer_text = popularItemData[i]._source.offer_text;
            finalDataSetSub[j].is_swap_items =
              popularItemData[i]._source.is_swap_items;
            finalDataSetSub[j].stock_quantities =
              popularItemData[i]._source.stock_quantities;
            finalDataSetSub[j].pack_ids = popularItemData[i]._source.pack_ids;
            finalDataSetSub[j].pack_names = popularItemData[i]._source.pack_names;
            finalDataSetSub[j].pack_names_without_unit =
              popularItemData[i]._source.pack_names;
            finalDataSetSub[j].logoPath = popularItemData[i]._source.logo_paths;
            finalDataSetSub[j].logo_path_thumbs =
              popularItemData[i]._source.logo_path_thumbs;
            finalDataSetSub[j].slug = popularItemData[i]._source.slug;
            finalDataSetSub[j].description = "";
            if (popularItemData[i]._source.displayitemprice === true) {
              if (
                popularItemData[i]._source.item_prices.toString() ===
                popularItemData[i]._source.mrps.toString()
              ) {
                finalDataSetSub[j].is_display_item_price = false;
                finalDataSetSub[j].mrps = popularItemData[i]._source.mrps;
                finalDataSetSub[j].item_prices = popularItemData[i]._source.mrps;
              } else {
                finalDataSetSub[j].is_display_item_price = true;
                finalDataSetSub[j].mrps = popularItemData[i]._source.mrps;
                finalDataSetSub[j].item_prices =
                  popularItemData[i]._source.item_prices;
              }
            } else {
              finalDataSetSub[j].is_display_item_price = false;
              finalDataSetSub[j].mrps = popularItemData[i]._source.mrps;
              finalDataSetSub[j].item_prices = popularItemData[i]._source.mrps;
            }
          }
        }
        finalDataSet[i].finalDataSetSub = finalDataSetSub;
        finalDataSet[i].is_display_item_price = popularItemData[i]._source.displayitemprice
        finalDataSet[i].isCartItemColorShow = 'none'
        finalDataSet[i].isCartItemOpenClass = ''
        finalDataSet[i].addcartActive = ''
        if (themeId === '36') {
          finalDataSet[i].cartLabel = '+'
        }
        else if (themeId === '34' || themeId === '29'|| themeId === '4') {
          finalDataSet[i].cartLabel = '<span><img src="https://storedigfiles.s3.amazonaws.com/production/themes/cartBtnLokal.png" /> ADD TO CART</span>'
        }
        else {
          finalDataSet[i].cartLabel = 'ADD TO CART'
        }
        finalDataSet[i].itemAdded = ''
        var cartItems = [];
        if (selectedProduct !== null && selectedProduct.length > 0) {
          for (var cartItem = 0; cartItem < selectedProduct.length; cartItem++) {
            if (selectedProduct[cartItem].store_item_stock_ids.toString() == finalDataSet[i].finalDataSetSub[0].store_item_stock_ids.toString()) {
              cartItems[0] = selectedProduct[cartItem]
              finalDataSet[i].cartItems = cartItems
              finalDataSet[i].isCartItemColorShow = 'block'
              finalDataSet[i].isCartItemOpenClass = 'cart_clk'
              if (themeId === '36') {
                finalDataSet[i].cartLabel = selectedProduct[cartItem].quantity
              }
              else if (themeId === '34' || themeId === '29'|| themeId === '4') {
                finalDataSet[i].cartLabel = '<img src="https://storedigfiles.s3.amazonaws.com/production/themes/cartBtnLokal.png" />'
              }
              else {
                finalDataSet[i].cartLabel = 'ADDED'
              }
              finalDataSet[i].itemAdded = 'itemAdded'
              var data = $.grep(finalDataSetSub, function (e) {
                return parseFloat(e.item_prices) === parseFloat(selectedProduct[cartItem].item_prices);
              });
              var data1 = $.grep(finalDataSetSub, function (e) {
                return parseFloat(e.item_prices) !== parseFloat(selectedProduct[cartItem].item_prices);
              });
              finalDataSet[i].finalDataSetSub = data.concat(data1);
            }
            else {
              finalDataSet[i].cartItems = cartItems
            }
          }
        }
        else {
          finalDataSet[i].cartItems = cartItems
        }
        finalDataSetNew.push(finalDataSet)
      }
      this.setState({
        itemDetails: finalDataSet,
        isLoading: '1',
        rowCount: totalCount,
        pageNumber: pageNumber,
        divPagination: '1',
        isData: '1',
        itemOffset: offset
      })
    }
    else {
      this.setState({
        isData: '0'
      })
    }
  }
  componentDidMount() {
    window.scrollTo(0, 0)
    window.sessionStorage.setItem('sortBy', 'rank')
    this.getCategory()
    const { match: { params } } = this.props;
    let category1Id = '';
    let category2Id = '';
    let category3Id = '';
    let searchKeyword = '';
    if (typeof params.searchKeyword !== "undefined" && params.searchKeyword !== null && params.searchKeyword !== '' && params.searchKeyword !== 'null' && params.searchKeyword !== '0') {
      searchKeyword = params.searchKeyword
      window.sessionStorage.setItem('searchKeyword', searchKeyword)
      window.sessionStorage.setItem('category1Id', '')
      window.sessionStorage.setItem('category2Id', '')
      window.sessionStorage.setItem('category3Id', '')
      window.sessionStorage.setItem('brandIds', '')
      window.sessionStorage.setItem('minPrice', '')
      window.sessionStorage.setItem('maxPrice', '')
      window.sessionStorage.setItem('sortBy', 'rank')
      $(".brandClass").attr('checked', false);
      window.sessionStorage.setItem('minPriceSelected', '')
      window.sessionStorage.setItem('maxPriceSelected', '')
    }
    else {
      window.sessionStorage.setItem('searchKeyword', '')
      if (params.categoryLevel1Id !== null && params.categoryLevel1Id !== '0' && params.categoryLevel1Id !== 'null' && params.categoryLevel1Id !== 0) {
        category1Id = params.categoryLevel1Id
        window.sessionStorage.setItem('category1Id', category1Id)
      }
      else {
        window.sessionStorage.setItem('category1Id', '')
      }
      if (params.categoryLevel2Id !== null && params.categoryLevel2Id !== '0' && params.categoryLevel2Id !== 'null' && params.categoryLevel2Id !== 0) {
        category2Id = params.categoryLevel2Id
        window.sessionStorage.setItem('category2Id', category2Id)
      }
      else {
        window.sessionStorage.setItem('category2Id', '')
      }
      if (params.categoryLevel3Id !== null && params.categoryLevel3Id !== '0' && params.categoryLevel3Id !== 'null' && params.categoryLevel3Id !== 0) {
        category3Id = params.categoryLevel3Id
        window.sessionStorage.setItem('category3Id', category3Id)
      }
      else {
        window.sessionStorage.setItem('category3Id', '')
      }
    }
    category1Id = (category1Id === null || category1Id === '0' || category1Id === 'null' || category1Id === 0) ? window.sessionStorage.getItem('category1Id') : category1Id;
    category2Id = (category2Id === null || category2Id === '0' || category2Id === 'null' || category2Id === 0) ? window.sessionStorage.getItem('category2Id') : category2Id;
    category3Id = (category3Id === null || category3Id === '0' || category3Id === 'null' || category1Id === 0) ? window.sessionStorage.getItem('category3Id') : category3Id;
    searchKeyword = (searchKeyword === null || searchKeyword === '' || searchKeyword === 'null') ? ((window.sessionStorage.getItem('searchKeyword') !== '' && window.sessionStorage.getItem('searchKeyword') !== null || window.sessionStorage.getItem('searchKeyword') !== 'null') ? window.sessionStorage.getItem('searchKeyword') : '') : searchKeyword;
    searchKeyword = searchKeyword === null ? '' : searchKeyword
    var offset = window.sessionStorage.getItem('offset2');
    var max = "40";
    window.sessionStorage.setItem('offset', offset)
    window.sessionStorage.setItem('max', max)
    let storeId = window.sessionStorage.getItem('storeId');
    const postData = {
      functionName: 'searchresult/searchResultItemListing',
      storeId: storeId,
      offset: offset != null ? offset : "0",
      max: max,
    }
    getData(postData).then((data) => {
      if (data.data.success === "1") {
        this.setState({
          imagePath: data.data.result.itemResult[0].imagePath,
          currencySymbol: data.data.result.itemResult[0].currencySymbol,
          cityId: data.data.result.itemResult[0].cityId,
          divPagination: '1',
          isLoading: '1',
          s3AccessKey: data.data.result.itemResult[0].s3bucketAccessKey,
          s3SecretKey: data.data.result.itemResult[0].s3bucketSecretKey
        });
        this.itemListing(searchKeyword, category1Id, category2Id, category3Id, offset, max, 1, '', '', '', '')
      }
    })
    this.setState({
      isShowFilterSection: true
    });
    if (this.state.isShowFilterSection === true) {
      this.triggerChildAlert()
    }
    var sellerID = "";
    var moduleID = 2;
    var siteCategoryGroupId = "";

    if (window.sessionStorage.getItem('siteCategoryId') !== "0") {
      siteCategoryGroupId = window.sessionStorage.getItem('siteCategoryId');
    }
    else {
      sellerID = window.sessionStorage.getItem('sellerId')
    }
    pageHitAPICall(sellerID, moduleID, siteCategoryGroupId)
  }
  async getCategory() {
    // debugger
    let storeId = window.sessionStorage.getItem('storeId');
    const client = new elasticsearch.Client({
      host: elasticHostURL(),
      amazonES: {
        region: 'us-west-1',
        // credentials: new AWS.Credentials(this.state.s3AccessKey, this.state.s3SecretKey)
      }
    });
    const body = await client.search({
      index: elasticLink() + '-autocomplete-loose',
      from: 0, size: 0,
      body: {
        query:
        {
          bool: {

            filter:
            {
              bool:
              {
                must: [{
                  terms: {
                    store_id: [storeId]
                  }
                }
                ]
              }
            }
          }
        },
        sort: [
          {
            category_level1_id: "asc"
          },
          {
            "category_level2_name.keyword": "asc"
          },
          {
            "category_level3_name.keyword": "asc"
          }
        ],
        "aggs": {
          "category_level1": {
            terms: {
              field: "category_level1_id",
              order: {
                "_term": "asc"
              }
            },
            "aggs": {
              "category_level1_name": {
                terms: {
                  field: "category_level1_name.keyword"

                },
                "aggs": {
                  "category_level1_image": {
                    terms: {
                      field: "category_level1_img.keyword"
                    },
                    "aggs": {
                      "category_level2": {
                        terms: {
                          field: "category_level2_id"

                        },
                        "aggs": {
                          "category_level2_name": {
                            terms: {
                              field: "category_level2_name.keyword"

                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }

      }
    });
    var categoryLevel1 = []
    for (const category_level1Id of body.aggregations.category_level1.buckets) {
      var categoryLevel2 = []
      if (null != category_level1Id.category_level1_name.buckets[0].category_level1_image.buckets[0]) {
        for (const category_level2Id of category_level1Id.category_level1_name.buckets[0].category_level1_image.buckets[0].category_level2.buckets) {
          var category1 = {
            category1_id: category_level1Id.key,
            category2_id: category_level2Id.key,
            category2_name: category_level2Id.category_level2_name.buckets[0].key
          }
          categoryLevel2.push(category1)
        }
      }
      var img = ''
      if (null != category_level1Id.category_level1_name.buckets[0].category_level1_image.buckets[0])
        img = category_level1Id.category_level1_name.buckets[0].category_level1_image.buckets[0].key

      var category1 = {
        name: category_level1Id.category_level1_name.buckets[0].key,
        category_level1_id: category_level1Id.key,
        level1ImagePath: img,
        categoryLevel2: categoryLevel2
      }
      categoryLevel1.push(category1)
    }
    {console.log("zzzzzzzzzzzzzzzzzzzzzzzzzzzzzz",categoryLevel1)}
    this.setState({
      category: categoryLevel1
    });
  }
  sortClick() {
    const currentState = this.state.sortActive;
    this.setState({ sortActive: !currentState });
  }
  contact() {
    $(".contactPopUp").show();
  }
  sortListClick(sortBy, type) {
    this.setState({
      isLoading: '0'
    })
    $("#sortBy").text(type);
    window.sessionStorage.setItem('sortBy', sortBy)
    let category1Id = (window.sessionStorage.getItem('category1Id') === null ? '' : window.sessionStorage.getItem('category1Id'));
    let category2Id = (window.sessionStorage.getItem('category2Id') === null ? '' : window.sessionStorage.getItem('category2Id'));
    let category3Id = (window.sessionStorage.getItem('category3Id') === null ? '' : window.sessionStorage.getItem('category3Id'));
    let brandIds = (window.sessionStorage.getItem('brandIds') === null ? '' : window.sessionStorage.getItem('brandIds'));
    let minPrice = (window.sessionStorage.getItem('minPrice') === null ? '' : window.sessionStorage.getItem('minPrice'));
    let maxPrice = (window.sessionStorage.getItem('maxPrice') === null ? '' : window.sessionStorage.getItem('maxPrice'));
    let offset = (window.sessionStorage.getItem('offset') === null ? 0 : window.sessionStorage.getItem('offset'));
    let max = (window.sessionStorage.getItem('max') === null ? 40 : window.sessionStorage.getItem('max'));
    let searchKeyword = ((window.sessionStorage.getItem('searchKeyword') != '' && window.sessionStorage.getItem('searchKeyword') !== null || window.sessionStorage.getItem('searchKeyword') !== 'null') ? window.sessionStorage.getItem('searchKeyword') : '');
    this.itemListing(searchKeyword, category1Id, category2Id, category3Id, offset, max, 1, brandIds, sortBy, minPrice, maxPrice)
  }
  onFilter(mode, category1Id, category2Id, category3Id, brandIds, minPrice, maxPrice, offset, max, pageNumber) {
    window.sessionStorage.setItem("offset2", offset)
    window.scrollTo(0, 0)
    this.setState({
      isLoading: '0'
    })
    if (mode === 'Main Category') {
      $(".topCategory").removeClass('tagActive')
      $("#topCategory" + category1Id).addClass('tagActive')
      window.sessionStorage.setItem('searchKeyword', '')
      window.sessionStorage.setItem('category1Id', '')
      window.sessionStorage.setItem('category2Id', '')
      window.sessionStorage.setItem('category3Id', '')
      window.sessionStorage.setItem('brandIds', '')
      window.sessionStorage.setItem('minPrice', '')
      window.sessionStorage.setItem('maxPrice', '')
      window.sessionStorage.setItem('sortBy', 'rank')
      $(".brandClass").attr('checked', false);
      window.sessionStorage.setItem('minPriceSelected', '')
      window.sessionStorage.setItem('maxPriceSelected', '')
      $("#sortBy").text('Sort By');
    }
    if (mode === 'Left Category') {
      window.sessionStorage.setItem('category1Id', category1Id)
      window.sessionStorage.setItem('category2Id', category2Id)
      window.sessionStorage.setItem('category3Id', category3Id)
    }
    else {
      if (category1Id !== null && category1Id !== '' && category1Id !== 'null') {
        window.sessionStorage.setItem('category1Id', category1Id)
      }
      if (category2Id !== null && category2Id !== '' && category2Id !== 'null') {
        window.sessionStorage.setItem('category2Id', category2Id)
      }
      if (category3Id !== null && category3Id !== '' && category3Id !== 'null') {
        window.sessionStorage.setItem('category3Id', category3Id)
      }
    }
    if (brandIds !== null && brandIds !== '' && brandIds !== 'null') {
      window.sessionStorage.setItem('brandIds', brandIds)
    }
    if (minPrice !== null && minPrice !== '' && minPrice !== 'null') {
      window.sessionStorage.setItem('minPrice', minPrice)
    }
    if (maxPrice !== null && maxPrice !== '' && maxPrice !== 'null') {
      window.sessionStorage.setItem('maxPrice', maxPrice)
    }
    if (offset !== null && offset !== '' && offset !== 'null') {
      window.sessionStorage.setItem('offset', offset)
    }
    if (max !== null && max !== '' && max !== 'null') {
      window.sessionStorage.setItem('max', max)
    }
    var minPrice = ((minPrice === '' || minPrice === null || minPrice === 'null') ? (window.sessionStorage.getItem('minPrice') === null ? '' : window.sessionStorage.getItem('minPrice')) : minPrice);
    var maxPrice = ((maxPrice === '' || maxPrice === null || maxPrice === 'null') ? (window.sessionStorage.getItem('maxPrice') === null ? '' : window.sessionStorage.getItem('maxPrice')) : maxPrice);
    var brandIds = ((brandIds === '' || brandIds === null || brandIds === 'null') ? (window.sessionStorage.getItem('brandIds') === null ? '' : window.sessionStorage.getItem('brandIds')) : brandIds);
    var category1Id = ((category1Id === '' || category1Id === null || category1Id === 'null') ? (window.sessionStorage.getItem('category1Id') === null ? '' : window.sessionStorage.getItem('category1Id')) : category1Id);
    var category2Id = ((category2Id === '' || category2Id === null || category2Id === 'null') ? (window.sessionStorage.getItem('category2Id') === null ? '' : window.sessionStorage.getItem('category2Id')) : category2Id);
    var category3Id = ((category3Id === '' || category3Id === null || category3Id === 'null') ? (window.sessionStorage.getItem('category3Id') === null ? '' : window.sessionStorage.getItem('category3Id')) : category3Id);
    var offset = ((offset === '' || offset === null || offset === 'null') ? (window.sessionStorage.getItem('offset') === null ? 0 : window.sessionStorage.getItem('offset')) : offset);
    var max = ((max === '' || max === null || max === 'null') ? (window.sessionStorage.getItem('max') === null ? 40 : window.sessionStorage.getItem('max')) : max);
    var sortBy = ((window.sessionStorage.getItem('sortBy') !== '' && window.sessionStorage.getItem('sortBy') !== null || window.sessionStorage.getItem('sortBy') !== 'null') ? window.sessionStorage.getItem('sortBy') : 'rank');
    var searchKeyword = ((window.sessionStorage.getItem('searchKeyword') !== '' && window.sessionStorage.getItem('searchKeyword') != null || window.sessionStorage.getItem('searchKeyword') !== 'null') ? window.sessionStorage.getItem('searchKeyword') : '');
    this.itemListing(searchKeyword, category1Id, category2Id, category3Id, offset, max, pageNumber, brandIds, sortBy, minPrice, maxPrice)
    this.setState({
      isShowFilterSection: true,
      isLoading: '1'
    });
    if (this.state.isShowFilterSection === true) {
      this.triggerChildAlert()
    }
  }
  render() {
    let itemOffset = this.state.itemOffset;
    let themeId = localStorage.getItem('themeId')
    return (
      <div className={themeId === '29' ? "coverWraper MraCoverWraper" : "coverWraper"}>
         <Suspense fallback={<div></div>}>
        <MainHeader></MainHeader>
        <Helmet>
          <meta name="description" content="Buy online and Home delivery at best price. These are available from your favorite stores at best prices. This will be a great and excellent experience on your online grocery shopping. please send us your feedback to our customer service, big saving, discounts" ></meta>
        </Helmet>
        <div className="contentWrap loakalcontentWrapTags">
          <div className={(themeId === '34' || themeId === '29'|| themeId === '4') ? "container" : ""}>
            <div className={themeId === '31' ? "itemCoverDiv perfumeListing" : "itemCoverDiv"}>
              <div className="itemListTop itemTopListing">
                <div className="tagSection">
                  {this.state.category.slice(0, 8).map((categoryLevel1Object, categoryLevel1Index) => (
                    <span className="tag topCategory" id={"topCategory" + categoryLevel1Object.category_level1_id} onClick={(e) => this.onFilter('Main Category', categoryLevel1Object.category_level1_id, '', '', '', '', '', '0', '40', '1')}>{categoryLevel1Object.name}</span>
                  ))}
                </div>
                {/* <div className="filterSection">
                  <div className="sortList">
                    <div className={this.state.sortActive ? 'sortListDiv sortListActive' : 'sortListDiv'} onClick={(e) => this.sortClick()}>
                      <span id="sortBy">Sort By{(themeId === '34' || themeId === '29') ? <img src={arowdown} alt=""/> : ""}</span>
                      <span onClick={(e) => this.sortListClick('rank', 'Popularity')}>Popularity</span>
                      <span onClick={(e) => this.sortListClick('asc', 'Price - Low to High')}>Price - Low to High</span>
                      <span onClick={(e) => this.sortListClick('desc', 'Price - High to Low')}>Price - High to Low</span>
                      <span onClick={(e) => this.sortListClick('offer', 'Offer')}>Offer</span>
                      <span onClick={(e) => this.sortListClick('name', 'Alphabetical')}>Alphabetical</span>
                    </div>
                  </div>
                </div> */}
              </div>
              <div className="itemListDiv">
              <div className="filterSection">
                  <div className="sortList">
                    <div className={this.state.sortActive ? 'sortListDiv sortListActive' : 'sortListDiv'} onClick={(e) => this.sortClick()}>
                      <span id="sortBy">Sort By{(themeId === '34' || themeId === '29'|| themeId === '4') ? <img src={arowdown} alt=""/> : ""}</span>
                      <span onClick={(e) => this.sortListClick('rank', 'Popularity')}>Popularity</span>
                      <span onClick={(e) => this.sortListClick('asc', 'Price - Low to High')}>Price - Low to High</span>
                      <span onClick={(e) => this.sortListClick('desc', 'Price - High to Low')}>Price - High to Low</span>
                      <span onClick={(e) => this.sortListClick('offer', 'Offer')}>Offer</span>
                      <span onClick={(e) => this.sortListClick('name', 'Alphabetical')}>Alphabetical</span>
                    </div>
                  </div>
                </div>
                {(this.state.isShowFilterSection === true && this.state.itemDetails.length > 0) ?
                  <FilterSectionDiv ref="filterSection" onFilterParent={this.onFilter} s3AccessKey={this.state.s3AccessKey} s3SecretKey={this.state.s3SecretKey}></FilterSectionDiv>
                  :
                  ''
                }
                {(() => {
                  if (this.state.isLoading === '0') {
                    return (
                      <Loader></Loader>
                    )
                  }
                  else if (this.state.itemDetails.length > 0) {
                    return (
                      <ItemListingData itemOffset={itemOffset} activePage={this.state.pageNumber} itemLoad={this.itemLoad} onFilterParent={this.onFilter} itemDetails={this.state.itemDetails} imagePath={this.state.imagePath} currencySymbol={this.state.currencySymbol} cityId={this.state.cityId} divPagination={this.state.divPagination} rowCount={this.state.rowCount}></ItemListingData>
                    )
                  }
                  else if (this.state.isData === '0') {
                    return (
                      <div className="itemSection productItemListing">
                        <div className="no-item-found noDataDiv">
                          <img src={lence} alt=""/>
                          <div className="noDataText"><img src={triangle} alt=""/>No item found, view our popular items</div>
                        </div>
                        <PopularItems></PopularItems>
                      </div>
                    )
                  }
                })()}
              </div>
            </div>
          </div>
        </div>
        <MainFooter></MainFooter>
        </Suspense>
      </div>
    )
  }
}
