import React, { Component, Suspense } from 'react'
import { Link } from 'react-router-dom';
import $ from "jquery";
import ArwBottom from "../images/lokal/arow-down.png"
import { pageHitAPICall } from '../services';
import { elasticLink, elasticHostURL } from "../config";
const AWS = require('aws-sdk');
const elasticsearch = require("elasticsearch");
AWS.config.region = 'us-west-1';
export default class StoreProfileleftmenu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isVegetablesOpen: false,
            isSpicesOpen: false,
            isPackedFoodOpen: false,
            isHouseholdItemsOpen: true,
            showPopup: false,
            selectedItem:[],
            category: [],
            storeData: []
        }
    }
    componentDidMount() {
        window.scrollTo(0, 0)
        this.getCategory()
        var sellerID = "";
        var moduleID = 12;
        var siteCategoryGroupId = "";

        if (window.sessionStorage.getItem('siteCategoryId') !== "0") {
            siteCategoryGroupId = window.sessionStorage.getItem('siteCategoryId');
        }
        else {
            sellerID = window.sessionStorage.getItem('sellerId')
        }
        pageHitAPICall(sellerID, moduleID, siteCategoryGroupId)
    }

    async getCategory() {
        let storeId = window.sessionStorage.getItem('storeId');
        const client = new elasticsearch.Client({
            host: elasticHostURL(),
            amazonES: {
                region: 'us-west-1',
                 //credentials: new AWS.Credentials(this.state.s3AccessKey, this.state.s3SecretKey)
            }
        });
        const body = await client.search({
            index: elasticLink() + '-autocomplete-loose',
            from: 0, size: 0,
            body: {
                query:
                {
                    bool: {
                        filter:
                        {
                            bool:
                            {
                                must: [{
                                    terms: {
                                        store_id: [storeId]
                                    }
                                }
                                ]
                            }
                        }
                    }
                },
                sort: [
                    {
                        category_level1_id: "asc"
                    },
                    {
                        "category_level2_name.keyword": "asc"
                    },
                    {
                        "category_level3_name.keyword": "asc"
                    }
                ],
                "aggs": {
                    "category_level1": {
                        terms: {
                            field: "category_level1_id",
                            order: {
                                "_term": "asc"
                            }
                        },
                        "aggs": {
                            "category_level1_name": {
                                terms: {
                                    field: "category_level1_name.keyword"

                                },
                                "aggs": {
                                    "category_level1_image": {
                                        terms: {
                                            field: "category_level1_img.keyword"
                                        },
                                        "aggs": {
                                            "category_level2": {
                                                terms: {
                                                    field: "category_level2_id"

                                                },
                                                "aggs": {
                                                    "category_level2_name": {
                                                        terms: {
                                                            field: "category_level2_name.keyword"

                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        });
        var categoryLevel1 = []
        for (const category_level1Id of body.aggregations.category_level1.buckets) {
            var categoryLevel2 = []
            if (null != category_level1Id.category_level1_name.buckets[0].category_level1_image.buckets[0]) {
                for (const category_level2Id of category_level1Id.category_level1_name.buckets[0].category_level1_image.buckets[0].category_level2.buckets) {
                    var category1 = {
                        category1_id: category_level1Id.key,
                        category2_id: category_level2Id.key,
                        category2_name: category_level2Id.category_level2_name.buckets[0].key
                    }
                    categoryLevel2.push(category1)
                }
            }
            var img = ''
            if (null != category_level1Id.category_level1_name.buckets[0].category_level1_image.buckets[0])
                img = category_level1Id.category_level1_name.buckets[0].category_level1_image.buckets[0].key
            var category1 = {
                name: category_level1Id.category_level1_name.buckets[0].key,
                category_level1_id: category_level1Id.key,
                level1ImagePath: img,
                categoryLevel2: categoryLevel2
            }
            categoryLevel1.push(category1)
        }
        {console.log("caycategoryLevel1",categoryLevel1)}
        this.setState({
            category: categoryLevel1
        });
    }
    toggleSection = (section) => {
        console.log(section);
        this.setState((prevState) => ({
            isVegetablesOpen: false,
            isSpicesOpen: false,
            isPackedFoodOpen: false,
            isHouseholdItemsOpen: false,
            [section]: !prevState[section] // Toggle the clicked section
        }));
    };
    fliterAccordion(index) {
        $("#accordionList" + index).toggleClass("fliterAccordionOpen");
        $("#accordionName" + index).toggleClass("accordionFliterActive");
    }
    render() {
        const { isVegetablesOpen, isSpicesOpen, isPackedFoodOpen, isHouseholdItemsOpen, showPopup, } = this.state;
        return (

            <div >
                <div className="store-profile-left">
                                <div className="store-profile-head">Pick Your Category</div>
                                <ul className="pick-category">
                                    <li className="store-profile-li">
                                        <div onClick={() => this.toggleSection('isVegetablesOpen')} className="li-header">
                                            <span className='cat-list'>Vegetables</span>
                                            {isVegetablesOpen ?
                                                <span className="cat-list-img">
                                                    <img src={ArwBottom} alt="Arwright" className='img-fluid rotate-arrow' />
                                                </span>
                                                :
                                                <span className="cat-list-img">
                                                    <img src={ArwBottom} alt="ArwBottom" className='img-fluid' />
                                                </span>
                                            }
                                        </div>
                                        {isVegetablesOpen && (
                                            <ul className="subList">
                                                <li><input type="checkbox" /> Vegetables1</li>
                                                <li><input type="checkbox" /> Vegetables2</li>
                                            </ul>
                                        )}
                                    </li>
                                    <div id="fliterCover" className="fliterCoverDiv">
                                    {this.state.category.map((categoryObj, index) => (
                                    <div className="fliterList">
                                        <h5 className="filterCategoryName" onClick={(e) => this.fliterAccordion(index)} id={"accordionName" + index}>{categoryObj.name}<span className="cat-list-img"><img src={ArwBottom} alt=""/></span></h5>
                                        <ul id={"accordionList" + index} className="subList">
                                            {categoryObj.categoryLevel2.map((category2) => (
                                                <li>
                                                    <Link class="filterListName" to={"/listing/" + category2.category1_id + "/" + category2.category2_id + "/0//" + categoryObj.name + '-' + category2.category2_name.split(' ').join('')}>{category2.category2_name}</Link>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                ))}
                                </div>
                                    {/* <li className="store-profile-li">
                                        <div onClick={() => this.toggleSection('isSpicesOpen')} className="li-header">

                                            <span className='cat-list'>Spices</span>
                                            {isSpicesOpen ?
                                                <span className="cat-list-img">
                                                    <img src={ArwBottom} alt="Arwright" className='img-fluid rotate-arrow' />
                                                </span>
                                                :
                                                <span className="cat-list-img">
                                                    <img src={ArwBottom} alt="ArwBottom" className='img-fluid' />
                                                </span>}
                                        </div>
                                        {isSpicesOpen && (
                                            <ul className="subList">
                                                <li><input type="checkbox" />Spices</li>
                                                <li><input type="checkbox" /> Spices</li>
                                            </ul>
                                        )}
                                    </li>
                                    <li className="store-profile-li">
                                        <div onClick={() => this.toggleSection('isPackedFoodOpen')} className="li-header">

                                            <span className='cat-list'> Packed Food</span>
                                            {isPackedFoodOpen ?
                                                <span className="cat-list-img">
                                                    <img src={ArwBottom} alt="Arwright" className='img-fluid rotate-arrow' />
                                                </span>
                                                :
                                                <span className="cat-list-img">
                                                    <img src={ArwBottom} alt="ArwBottom" className='img-fluid' />
                                                </span>
                                            }
                                        </div>
                                        {isPackedFoodOpen && (
                                            <ul className="subList">
                                                <li><input type="checkbox" />  Packed Food</li>
                                                <li><input type="checkbox" />  Packed Food</li>
                                            </ul>
                                        )}
                                    </li>
                                    <li className="store-profile-li active">
                                        <div onClick={() => this.toggleSection('isHouseholdItemsOpen')} className="li-header">

                                            <span className='cat-list'>Household Items </span>
                                            {isHouseholdItemsOpen ?
                                                <span className="cat-list-img">
                                                    <img src={ArwBottom} alt="Arwright" className='img-fluid rotate-arrow' />
                                                </span>
                                                :
                                                <span className="cat-list-img">
                                                    <img src={ArwBottom} alt="ArwBottom" className='img-fluid' />
                                                </span>
                                            }
                                        </div>
                                        {isHouseholdItemsOpen && (
                                            <ul className="subList">
                                                <li><input type="checkbox" /> Toilet & Floor Cleaners</li>
                                                <li><input type="checkbox" /> Pooja Items</li>
                                                <li><input type="checkbox" /> Cleaning Accessories</li>
                                            </ul>
                                        )}
                                    </li> */}
                                </ul>
                            </div>
            </div>

        )
    }
}