import React, { Component } from 'react';
import $ from "jquery";
export default class addcartBtn extends Component {
  constructor(props) {
    super(props)
    this.state = {
      itemId: this.props.itemId,
      size: this.props.itemSize,
      isAdded: false,
      cartSize: this.props.cartSize,
      isSizeSelected: false,
      sizeID: "",
      sizename: "",
      cmsizeId: "",
    };
    this.addToCartCommonItem = this.addToCartCommonItem.bind(this);
    this.isNumberCommonItem = this.isNumberCommonItem.bind(this);
  }
  componentDidMount() {
    $(".productdisplay" + this.props.itemId).hide()
  }
  sizeClose() {
    $(".productdisplay" + this.props.itemId).hide()
  }
  addtoCartCheck(sizeId, cmItemSizeId, sizeName) {

    let themeId = localStorage.getItem('themeId')
    let itemId = this.props.itemId
    let itemNo = this.props.index + "-"
    $("#check" + itemId + sizeId).is(':checked')
    let isDelete = false;
    $(".productdisplay" + itemId).hide();
    $("#profileSizeValidation").text("")
    if (this.props.fromPage === "itemProfile" && this.state.isSizeSelected !== true && themeId !== '2') {
      this.setState({
        isSizeSelected: true,
        sizeID: sizeId,
        sizename: sizeName,
        cmsizeId: cmItemSizeId,
      })
    }
    else {
      this.setState({
        isSizeSelected: false,
        sizeID: '',
        sizename: '',
        cmsizeId: '',
      })
      if (this.state.isSizeSelected === false) {
        this.updateCartCommonItem(this.props.storeId, this.props.itemId, this.props.itemName,(this.props.quantity), this.props.itemTitle, this.props.brand, sizeName, sizeId, cmItemSizeId, this.props.itemMasterId, this.props.netAmount, this.props.mrp, this.props.itemImage, isDelete, this.props.maxBuyCount)
        $("#profileSizeSuccess").text("Item added to cart")
        setTimeout(function () { $("#profileSizeSuccess").text("") }, 2000);
      }
    }
    if (themeId === '36') {
      $('#addToCart' + itemNo).addClass("cart_clk");
    }
    if (this.props.fromPage === "Listing") {
      this.props.itemLoad()
    }
    else if (this.props.fromPage === "itemProfile") {
      this.props.itemLoad(this.props.itemId)
    }
    else {
      this.props.getCategory(this.props.storeId)
      this.props.getAllCategory(this.props.storeId)
    }
  }
  addToCartCommonItemSize(itemId) {

    $(".sizeList").removeClass("sizeListActive");
    if (this.props.sizeId === "" && localStorage.getItem('themeId') === '38') {
      $(".productdisplay" + itemId).hide()
      $("#su_cart_alert" + itemId).show()
      setTimeout(() => {
        $("#su_cart_alert" + itemId).hide()
      }, 1000);
    }
    else if (this.props.fromPage !== 'itemProfile' && localStorage.getItem('themeId') !== '38' && localStorage.getItem('themeId') !== '2') {
      $(".productdisplay" + itemId).show()
    }
    else if (this.props.sizeId !== "" && (localStorage.getItem('themeId') === '38' || localStorage.getItem('themeId') === '2')) {
      this.addtoCartCheck(this.props.sizeId, this.props.cmsizeId, this.props.sizeName)
      $("#cartSuccessMessage" + itemId).show()
      setTimeout(() => {
        $("#cartSuccessMessage" + itemId).hide()
      }, 1000);
    }
    else if (typeof this.props.sizeId !== "undefined" && this.props.sizeId !== "" && this.props.fromPage !== "itemProfile" && localStorage.getItem('themeId') === '28') {
      this.addtoCartCheck('', '', '', '', '', '', this.props.sizeName, this.props.sizeId, this.props.cmsizeId, '', '', '', '', '', '', '')
    }
    else if (this.props.sizeId !== "" && this.state.isSizeSelected === true && this.props.fromPage === "itemProfile" && localStorage.getItem('themeId') === '28') {
      let isDelete = false;
      this.updateCartCommonItem(this.props.storeId, this.props.itemId, this.props.itemName,this.props.quantity, this.props.itemTitle, this.props.brand, this.state.sizename, this.state.sizeID, this.state.cmsizeId.toString().trim(), this.props.itemMasterId, this.props.netAmount, this.props.mrp, this.props.itemImage, isDelete, this.props.maxBuyCount)
      $("#profileSizeSuccess").text("Item added to cart")
      setTimeout(function () { $("#profileSizeSuccess").text("") }, 2000);
      this.setState({
        isSizeSelected: false,
        sizeID: '',
        sizename: '',
        cmsizeId: '',
      })
    }

    else if (this.props.sizeId !== "" && this.state.isSizeSelected === true && this.props.fromPage === "itemProfile" && localStorage.getItem('themeId') === '34') {
      let isDelete = false;
      this.updateCartCommonItem(this.props.storeId, this.props.itemId, this.props.itemName, '1', this.props.itemTitle, this.props.brand, this.state.sizename, this.state.sizeID, this.state.cmsizeId.toString().trim(), this.props.itemMasterId, this.props.netAmount, this.props.mrp, this.props.itemImage, isDelete, this.props.maxBuyCount)
      $("#profileSizeSuccess").text("Item added to cart")
      setTimeout(function () { $("#profileSizeSuccess").text("") }, 2000);
      this.setState({
        isSizeSelected: false,
        sizeID: '',
        sizename: '',
        cmsizeId: '',
      })
    }
    else {
      $("#profileSizeValidation").text("Please select your size")
      $("#profileSize").show();
    }
  }
  addToCartCommonItem(type) {

    let maxBuyCount = this.props.maxBuyCount
    let availableQuantity = this.props.availableQuantity
    let itemId = this.props.itemId
    let isDelete = false;
    let itemNo = this.props.index + "-"
    $("#qtyError" + itemNo).hide();
    if (type == "addToCart") {
      $('#addToCart' + itemNo).addClass("cart_clk");
      if ($('.cartInput' + itemNo).val() == 0) {
        $('.cartInput' + itemNo).val(1);
      }
    }
    else if (type == "inc") {
      var addItem = $('.cartInput' + itemNo).val()
      if (parseInt(addItem) >= parseInt(availableQuantity)) {
        $("#qtyError" + itemNo).show();
        $("#qtyError" + itemNo).text("Only " + availableQuantity + " qty available")
        $("#itemCount" + itemNo).text($('.cartInput' + itemNo).val())
      }
      else if (parseInt(addItem) >= parseInt(maxBuyCount)) {
        $("#qtyError" + itemNo).show();
        $("#qtyError" + itemNo).text("You can buy maximum " + maxBuyCount + " quantity")
        $("#itemCount" + itemNo).text($('.cartInput' + itemNo).val())
      }
      else {
        $("#qtyError" + itemNo).hide();
        $("#qtyError" + itemNo).text("")
        if (addItem > 0) {
          addItem++;
          $('.cartInput' + itemNo).val(addItem);
        }
        else if (addItem == 0) {
          $('.cartInput' + itemNo).val(1);
        }
      }
    }
    else if (type == "dec") {
      var removeItem = $('.cartInput' + itemNo).val()
      removeItem--;
      if (removeItem == 0) {
        isDelete = true;
        $('#addToCart' + itemNo).removeClass("cart_clk");
      }
      $('.cartInput' + itemNo).val(removeItem);
    }
    else if (type == "txtChange") {
      var txtItem = $('.cartInput' + itemNo).val()
      if (parseInt(txtItem) >= parseInt(availableQuantity)) {
        $("#qtyError" + itemNo).show();
        $("#qtyError" + itemNo).text("Only " + availableQuantity + " qty available")
        $("#itemCount" + itemNo).text($('.cartInput' + itemNo).val())
        $('.cartInput' + itemNo).val(availableQuantity)
      }
      else if (parseInt(txtItem) >= parseInt(maxBuyCount)) {
        $("#qtyError" + itemNo).show();
        $("#qtyError" + itemNo).text("You can buy maximum " + maxBuyCount + " quantity")
        $("#itemCount" + itemNo).text($('.cartInput' + itemNo).val())
      }
      else {
        if (txtItem == 0) {
          $('#addToCart' + itemNo).removeClass("cart_clk");
        }
        else {
          $('.cartInput' + itemNo).val(txtItem);
        }
      }
    }
    if ($('#txtQty' + itemNo).val() == 0) {
      this.setState({
        isAdded: false
      });
      isDelete = true;
    }
    else {
      this.setState({
        isAdded: true
      });
    }
    this.updateCartCommonItem(this.props.storeId, this.props.itemId, this.props.itemName, $('.cartInput' + itemNo).val(), this.props.itemTitle, this.props.brand, '', '', '', this.props.itemMasterId, this.props.netAmount, this.props.mrp, this.props.itemImage, isDelete, this.props.maxBuyCount, this.props.availableQuantity)
    if (this.props.fromPage === "Listing") {
      this.props.itemLoad()
    }
    else if (this.props.fromPage === "itemProfile") {
      this.props.itemLoad(this.props.itemId)
    }
    else {
      this.props.getCategory(this.props.storeId)
      this.props.getAllCategory(this.props.storeId)
    }
  }
  updateCartCommonItem(storeId, itemId, itemName, quantity, itemTitle, brand, sizeName, sizeId, cmItemSizeId, itemMasterId, netAmount, mrp, itemImage, isDelete, maxBuyCount, availableQuantity) {

    var selectCommonItemCart = JSON.parse(localStorage.getItem('selectCommonItemCart')) || []
    let itemNo = itemId + '-' + cmItemSizeId
    if (localStorage.getItem('selectCommonItemCart') === null) {
      if (isDelete === false || quantity > 0) {
        var selectedCommonItemProduct = {
          storeId: storeId,
          itemId: itemId,
          itemName: itemName,
          quantity: quantity,
          itemTitle: itemTitle,
          brand: brand,
          sizeName: sizeName,
          sizeId: sizeId,
          cmItemSizeId: cmItemSizeId,
          itemMasterId: itemMasterId,
          netAmount: netAmount,
          mrp: mrp,
          itemImage: itemImage,
          itemNo: itemNo,
          maxBuyCount: maxBuyCount,
          availableQuantity: availableQuantity
        }
        selectCommonItemCart.push(selectedCommonItemProduct)
        localStorage.setItem('selectCommonItemCart', JSON.stringify(selectCommonItemCart))
      }
    }
    else {
      var cookieNewCommonItemValue = [];
      var cookieCommonItemArray = selectCommonItemCart;
      var cookieCommonItemUpdated = false
      var cookieCommonItemNewValueCount = 0;
      for (var i = 0; i < cookieCommonItemArray.length; i++) {
        if (cookieCommonItemArray[i] !== null && cookieCommonItemArray[i] !== "") {
          var cookieStoreId = cookieCommonItemArray[i].storeId
          var cookieItemId = cookieCommonItemArray[i].itemId
          var cookieCmItemSizeId = cookieCommonItemArray[i].cmItemSizeId
          if (itemId === cookieItemId && storeId === cookieStoreId && cookieCmItemSizeId === cmItemSizeId) {
            if (isDelete === false && quantity > 0) {
              cookieNewCommonItemValue[cookieCommonItemNewValueCount] =
              {
                storeId: storeId,
                itemId: itemId,
                itemName: itemName,
                quantity: quantity,
                itemTitle: itemTitle,
                brand: brand,
                sizeName: sizeName,
                sizeId: sizeId,
                cmItemSizeId: cmItemSizeId,
                itemMasterId: itemMasterId,
                netAmount: netAmount,
                mrp: mrp,
                itemImage: itemImage,
                itemNo: itemNo,
                maxBuyCount: maxBuyCount,
                availableQuantity: availableQuantity
              }
              cookieCommonItemNewValueCount++;
            }
            cookieCommonItemUpdated = true
          }
          else {
            cookieNewCommonItemValue[cookieCommonItemNewValueCount] = cookieCommonItemArray[i];
            cookieCommonItemNewValueCount++;
          }
        }
      }
      if (cookieCommonItemUpdated === false) {
        if (quantity > 0) {
          cookieNewCommonItemValue[cookieCommonItemNewValueCount] = {
            storeId: storeId,
            itemId: itemId,
            itemName: itemName,
            quantity: quantity,
            itemTitle: itemTitle,
            brand: brand,
            sizeName: sizeName,
            sizeId: sizeId,
            cmItemSizeId: cmItemSizeId,
            itemMasterId: itemMasterId,
            netAmount: netAmount,
            mrp: mrp,
            itemImage: itemImage,
            itemNo: itemNo,
            maxBuyCount: maxBuyCount,
            availableQuantity: availableQuantity
          }
          cookieCommonItemUpdated = true
        }
      }
      localStorage.setItem('selectCommonItemCart', JSON.stringify(cookieNewCommonItemValue))
    }
    let cartLength = 0;
    if (localStorage.getItem('selectedCartProduct') !== null) {
      cartLength = parseInt(cartLength) + (JSON.parse(localStorage.getItem('selectedCartProduct')).length);
    }
    if (localStorage.getItem('selectRestaurantCart') !== null) {
      cartLength = parseInt(cartLength) + (JSON.parse(localStorage.getItem('selectRestaurantCart')).length);
    }
    if (localStorage.getItem('selectCommonItemCart') !== null) {
      cartLength = parseInt(cartLength) + (JSON.parse(localStorage.getItem('selectCommonItemCart')).length);
    }
    $("#cartCount").text(cartLength)
  }
  isNumberCommonItem = (e) => {
    let maxBuyCount = this.props.maxBuyCount
    var itemNo = this.props.index + "-";
    const re = /^[0-9\b]+$/;
    if (e.target.value === '' || re.test(e.target.value)) {
      var prnum = $('.cartInput' + itemNo).val();
    }
    else {
      $('.cartInput' + itemNo).val(1)
    }
    if (maxBuyCount < $('.cartInput' + itemNo).val()) {
      $('.cartInput' + itemNo).val(maxBuyCount)
    }
    this.addToCartCommonItem("txtChange")
  }
  sizeChart = (e) => {
    $(".locationPopUpsImg").show();
  }
  closeVideoPopup() {
    $(".locationPopUpsImg").hide();
  }
  render() {
    let themeId = localStorage.getItem('themeId')
    return (
      <div>
        {this.props.fromPage == 'itemProfile' && this.props.itemSize.length > 0 ?
          <div>
            <label id="profileSizeValidation"></label>
            <label id="profileSizeSuccess"></label>
            <div id="profileSize">
              <div class="sizeCartTop profileMargin">
                <h3>AVAILABLE SIZE</h3>
                {this.props.itemSizeChart != "" && this.props.itemSizeChart != null ?
                  <span className='sizechart-btn' onClick={(e) => this.sizeChart()}>SIZE CHART</span>
                  : ''}
              </div>
              <div class="profileSizechart prfpage-sizelist">
                {/* {this.props.itemSize.map((itemSizeObject, sizeArrayIndex) => (     
                  <span class="sizeList" id={"sizeLabel" + itemSizeObject.cmItemSizeId} onClick={(e) => {
                    const element = e.target;
    if (element.classList.contains("sizeListActive")) {
      element.classList.remove("sizeListActive");
    } else {
      element.classList.add("sizeListActive");     
    }
                   this.addtoCartCheck(itemSizeObject.sizeId, itemSizeObject.cmItemSizeId, itemSizeObject.sizeName)}}>{itemSizeObject.sizeName}</span>
                ))} */}
                {this.props.itemSize.map((itemSizeObject, sizeArrayIndex) => (
                  <span
                    className={`sizeList ${itemSizeObject.sizeQuantity > 0 ? '' : 'cmOutOfStock'}`}
                    id={"sizeLabel" + itemSizeObject.cmItemSizeId}
                    onClick={(e) => {
                      const clickedElement = e.currentTarget;

                      // Remove "sizeListActive" class from all elements
                      document.querySelectorAll('.sizeList').forEach(element => {
                        if (element !== clickedElement) {
                          element.classList.remove('sizeListActive');
                        }
                      });

                      // Toggle "sizeListActive" class on the clicked element
                      clickedElement.classList.toggle("sizeListActive");

                      this.addtoCartCheck(itemSizeObject.sizeId, itemSizeObject.cmItemSizeId, itemSizeObject.sizeName);
                    }}
                  >
                    {itemSizeObject.sizeName}
                  </span>
                ))}



              </div>
            </div>
          </div>
          : ''}
        <div class={"sizeWrap productdisplay" + this.props.itemId} id={"productdisplay" + this.props.itemId}>
          <div class="itemSizeClick">
            Select a size
            <span class="productclose" onClick={(e) => this.sizeClose()}>&times;</span>
          </div>
          <div className="sizeDiv sizeListWidth">
            {this.props.itemSize.map((itemSizeObject, itemSizeIndex) => (
              <span class={"sizeList " + (itemSizeObject.sizeQuantity > 0 ? "" : "cmOutOfStock")} id={"check" + this.props.itemId + itemSizeObject.sizeId} name="check" onClick={(e) => this.addtoCartCheck(itemSizeObject.sizeId, itemSizeObject.cmItemSizeId, itemSizeObject.sizeName)}>{itemSizeObject.sizeName}</span>
            ))}
          </div>
          <input type="hidden" id="hidSize" />
        </div>
        <div>
          {(() => {
            if (this.props.itemSize.length != 0) {
              if (themeId === '36') {
                return (
                  <div className="addcartbtn">
                    <div id={"addToCart" + this.props.index + "-"} class="cartButton" onClick={(e) => this.addToCartCommonItemSize(this.props.itemId)}>{this.props.fromPage == 'itemProfile' ? 'ADD TO CART ' : '+'}</div>
                  </div>
                )
              }
              else if (themeId === '38' || themeId === '28') {
                return (
                  <div className="addcartbtn" onClick={(e) => this.addToCartCommonItemSize(this.props.itemId)}>
                    <div id={"addToCart" + this.props.index + "-"} class="cartButton" >ADD TO CART </div>
                  </div>
                )
              }
              else {
                return (
                  <div className="addcartbtn">
                    <div id={"addToCart" + this.props.index + "-"} class="cartButton" onClick={(e) => this.addToCartCommonItemSize(this.props.itemId)}>{this.props.cartSize > 0 ? 'ADDED' : 'ADD TO CART '}</div>
                  </div>
                )
              }
            }
            else {
              return (
                <div className="addcartbtn">
                  <div class="qty">
                    <span class="dec" onClick={(e) => this.addToCartCommonItem("dec")} id={"minus" + this.props.index + "-"}>-</span>
                    <span class="cartNumber">
                      <input type="text" value={this.props.cartSize} className={"cartInput cartInput" + this.props.index + "-"} id={"txtQty" + this.props.index + "-"} onChange={this.isNumberCommonItem} onClick={(e) => this.addToCartCommonItem("txtChange")} /></span>
                    <span class="inc addBtn" onClick={(e) => this.addToCartCommonItem("inc")} id={"add" + this.props.index + "-"}>+</span>
                  </div>
                  {(() => {
                    if (themeId === '36') {
                      if (this.props.cartSize > 0) {
                        return (
                          <div id={"addToCart" + this.props.index + "-"} class="cartButton cart_clk" onClick={(e) => this.addToCartCommonItem("addToCart")}>+</div>
                        )
                      }
                      else {
                        return (
                          <div id={"addToCart" + this.props.index + "-"} class="cartButton" onClick={(e) => this.addToCartCommonItem("addToCart")}>+</div>
                        )
                      }
                    }
                    else {
                      return (
                        <div id={"addToCart" + this.props.index + "-"} class={"cartButton " + (this.props.cartSize > 0 ? "cart_clk" : "")} onClick={(e) => this.addToCartCommonItem("addToCart")}>{this.props.cartSize > 0 ? 'ADDED' : 'ADD TO CART'}</div>
                      )
                    }
                  })()}
                </div>
              )
            }
          })()}
        </div>
        <div className="popupWrap locationPopUpsImg popupWrapoverlay" style={{ display: 'none' }}>
          <div className="confirmWrappers sizechart-width">
            <button onClick={(e) => { this.closeVideoPopup() }} className='videoPopupClose'><span class="close">&times;</span></button>
            <img src={this.props.itemSizeChart} alt="" className='img-fluid' />
          </div>
        </div>
      </div>
    )
  }
}