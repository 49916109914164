import React, { Component } from "react";
import { checkTransactionStatus } from '../worldLine/apiService'
import {getCartItemsMultiple } from '../services';
// import WorldlineOrderSuccess from '../worldLine/orderSuccess'
//  import WorldLineFailed from '../worldLine/worldLineFailed'
export default class TransactionResponse extends Component {
  constructor(props) {
    super(props)
    this.state = {
      paymentStatus: '',
      wlOrderId:''
    }
  }
  componentDidMount() {

    const { match } = this.props;
    const { transactionId } = match.params;
    console.log('Transaction ID:', transactionId);
    //  var transactionStatus = await checkTransactionStatus(orderId,"WL0000000027698","6375b97b954b37f956966977e5753ee6")
    const postData = {
        "functionName": "phonePayStatus",
        "merchantTransactionId": transactionId
      }
      getCartItemsMultiple(postData).then((data) => {
      if (data.data.success === "1") {
      console.log("xxxxxxxxxccccccccccccccccccc",data)
      if(data.data.result.paymentStatus === "PAYMENT_SUCCESS"){
        this.getData(data.data.result.paymentId)
      }
      }
    })

  }
  async getData(paymentId) {    
          var postData = {
            stripeStoreId: "",
            functionName: "saleConfirm",
            device: "Web",
            locationId: localStorage.getItem('locationId'),
            buyerId: localStorage.getItem('userId'),
            buyerDeliveryAddressId: window.sessionStorage.getItem('buyerDeliveryAddressId'),
            buyerBillingAddressId:window.sessionStorage.getItem('buyerBillingAddressId'),
            siteId: localStorage.getItem('siteId'),
            cityId: localStorage.getItem('cityId'),
            razorPayDetails: [],
            worldLineDetails:[],
            phonePayDetails:[{paymentId:paymentId}],
            stripeId:"",
            paymentId: 5,
            commonItem: JSON.parse(localStorage.getItem('commonItemArrayWL')),
            restaurant: JSON.parse(localStorage.getItem('restaurantArrayWL')),
            grocery: JSON.parse(localStorage.getItem('groceryArrayWL')),
            hasLocation: localStorage.getItem('hasLocation'),
            timeZone: new Date().getTimezoneOffset().toString(),
            itemBeforePaymentGroupCode: localStorage.getItem('itemBeforePaymentGroupCode')
          }
          getCartItemsMultiple(postData).then((data) => {
            console.log("resulttttttttttttt",data)
            // alert(data)    
            //  alert(" data.data.success......." + data.data.success)           
            if (data.data.success == 1) {
              localStorage.removeItem('commonItemArrayWL')
              localStorage.removeItem('restaurantArrayWL')
              localStorage.removeItem('groceryArrayWL')
            //   localStorage.setItem('orderSummery', JSON.stringify(data.data.result));
              localStorage.setItem('orderSummery', JSON.stringify(data.data.result));
            //   localStorage.setItem('hidStoreIdForCheckout', storeId);
              window.location.href = "/OrderSummary-ThankYou-Order-placed-successfully"
            }
            else {
              alert(data.data.errorMessage)
            }
          });   
    }
  render() {
    return (
      (() => {
        // <>
        // <div>hlo</div>
        // </>
        // if (this.state.paymentStatus === 'S') {
        //   return (
        //     // <WorldlineOrderSuccess></WorldlineOrderSuccess>
        //   )
        // }
        // else if (this.state.paymentStatus === 'F') {
        //   return (
        //     <WorldLineFailed text1={"failed"} text2={"Order ID"} refID={this.state.wlOrderId}></WorldLineFailed>
        //   )
        // }
        // else {
          return (
          <div><center><h1>Please do not refresh this page. Processing...</h1></center></div>
          )
        // }
      })()
    )
  }
}