import React, { Component, Suspense } from 'react';

import $ from "jquery";
import { elasticLink,elasticHostURL } from "../config";
import GlobalSearchData from "../lokalIn/globalSearchData"
import GlobalSearchStoreData from "../lokalIn/globalSearchStoreData"
import arrow from '../images/lokal/down.png'
const AWS = require('aws-sdk');
const elasticsearch = require("elasticsearch");
AWS.config.region = 'us-west-1';
let Header = React.lazy(() => import("../" + localStorage.getItem("themeName") + "/header"));
let Footer = React.lazy(() => import("../" + localStorage.getItem("themeName") + "/footer"));
export default class Globalsearchresult extends Component {
    constructor() {
        super()
        this.state = {
            itemData: [],
            itemDataOld: [],
            storeData: [],
            searchKey: '',
            mode: '',
            offset: 0,
            max: 40,
            pageNumber: 2,
            totalCount: 0,
            categoryResult: [],
            brandResult: [],
            priceResult: [],
            minPrice: '',
            maxPrice: '',
            value: ''
        }
        this.showAlert = this.showAlert.bind(this);
        this.loadMoreData = this.loadMoreData.bind(this)
        this.globalSearch = this.globalSearch.bind(this)
    }
    async globalSearch(mode, searchKey, offset, max) {
        //debugger
        // var storeIdsBasedOnLocation = window.sessionStorage.getItem('storeIdsBasedOnLocation').toString().split(',')
        const client = new elasticsearch.Client({
            host: elasticHostURL(),
            amazonES: {
                region: 'us-west-1',
                //credentials: new AWS.Credentials(this.state.s3AccessKey, this.state.s3SecretKey)
            }
        });
        let siteId = localStorage.getItem('siteId');
        const body = await client.search({
            index: elasticLink() + '-global-search',
            from: offset, size: max,
            body: {
                query:
                {
                    bool: {
                        "must": {
                            "multi_match": {
                                "query": searchKey,
                                "type": "phrase_prefix",
                                "fields": ["search_item_name"]
                            }
                        },
                        filter:
                        {
                            bool:
                            {
                                must: [
                                    {
                                        "terms": {
                                            "site_id":[siteId]
                                        }
                                    },
                                    {
                                        "match": {
                                            "section_name":"Grocery"
                                        }
                                    }
                                ]
                                // [{
                                //     terms: {
                                //         site_id: [siteId]
                                //     }
                                // }
                                
                                // ]
                            }
                        }
                    }
                }
            }
        }); 
        console.log("@@@@@@@@@@@@@",body.hits.hits)       
        if (mode === "Store") {
            const sessionStoreId = window.sessionStorage.getItem('storeId');
            var rowData = $.merge($.merge([], this.state.itemDataOld), body.hits.hits);

            const filteredStoreData = rowData.filter(item => item._source.store_id == sessionStoreId);
            this.setState({ itemData: filteredStoreData, searchKey: searchKey, mode: mode, storeData: [], totalCount:filteredStoreData.length })
        }
        else {
            var rowData = $.merge($.merge([], this.state.itemDataOld), body.hits.hits);           
            this.setState({ itemData: rowData, searchKey: searchKey, mode: mode, storeData: [], totalCount: body.hits.total.value })
        }
    }
    componentDidMount() {

        window.scrollTo(0, 0)
        const { searchKey, mode } = this.props.location.state;
        this.globalSearch(mode, searchKey, 0, 40)
        // this.showAlert();
    }
    showAlert() {
        //debugger
        var txtUserSearch = ((window.sessionStorage.getItem('searchKeyword') !== '' && window.sessionStorage.getItem('searchKeyword') !== null && window.sessionStorage.getItem('searchKeyword') !== 'null') ? window.sessionStorage.getItem('searchKeyword') : '*');
        var category1 = ((window.sessionStorage.getItem('category1Id') === '' || window.sessionStorage.getItem('category1Id') === null) ? '*' : window.sessionStorage.getItem('category1Id'));
        var category2 = ((window.sessionStorage.getItem('category2Id') === '' || window.sessionStorage.getItem('category2Id') === null) ? '*' : window.sessionStorage.getItem('category2Id'));
        var category3 = ((window.sessionStorage.getItem('category3Id') === '' || window.sessionStorage.getItem('category3Id') === null) ? '*' : window.sessionStorage.getItem('category3Id'));
        var brandID = ((window.sessionStorage.getItem('brandIds') === '' || window.sessionStorage.getItem('brandIds') === null) ? '*' : window.sessionStorage.getItem('brandIds'));
        var minPrc = ((window.sessionStorage.getItem('minPrice') === '' || window.sessionStorage.getItem('minPrice') === null) ? '*' : window.sessionStorage.getItem('minPrice'));
        var maxPrc = ((window.sessionStorage.getItem('maxPrice') === '' || window.sessionStorage.getItem('maxPrice') === null) ? '*' : window.sessionStorage.getItem('maxPrice'));
        this.category(txtUserSearch, category1, category2, category3, brandID, minPrc, maxPrc)
    }
    componentWillReceiveProps(nextProps) {        
        const { searchKey, mode } = nextProps.location.state;
        if (this.state.searchKey !== searchKey || this.state.mode !== mode) {
            window.scrollTo(0, 0)
            this.setState({ pageNumber: 2, itemDataOld: [] })
            this.globalSearch(mode, searchKey, 0, 40)
        }
    }
    loadMoreData() {        
        var offset = (this.state.pageNumber - 1) * 40;       
        this.setState({ pageNumber: parseInt(this.state.pageNumber + 1), itemDataOld: this.state.itemData })
        this.globalSearch(this.state.mode, this.state.searchKey, offset, this.state.max)     
    }
    async category(txtUserSearch, category1, category2, category3, brandID, minPrc, maxPrc) {
        //debugger
        const client = new elasticsearch.Client({
            host: elasticHostURL(),
            amazonES: {
                region: 'us-west-1',
                // credentials: new AWS.Credentials(this.props.s3AccessKey, this.props.s3SecretKey)
            }
        });
        let storeId = window.sessionStorage.getItem('storeId');
        brandID = (brandID === '' ? '*' : brandID.replace(/ /g, "-"));
        var filters = [];
        var fields = ['store_id', 'category_level1_id', 'category_level2_id', 'category_level3_id', 'sold_out', 'brand_id'];
        var paramsVal = [storeId, category1, category2, category3, '0', brandID]
        for (var k = 0; k < fields.length; k++) {
            if (paramsVal[k] !== '*' && paramsVal[k] !== '' && paramsVal[k] !== null && paramsVal[k] !== "null" ) {
                if (fields[k] === 'brand_id') {
                    var brandSplit = paramsVal[k].toString().split('-')
                    var termq = { terms: {} };
                    termq.terms[fields[k]] = brandSplit;
                    filters.push(termq);
                }
                else {
                    var termq = { term: {} };
                    termq.term[fields[k]] = paramsVal[k];
                    filters.push(termq);
                }
            }
        }
        if (minPrc !== '*' && minPrc !== '') {
            filters.push({ range: { net_amount: { gte: minPrc, lte: maxPrc } } });
        }
        if (txtUserSearch !== '*') {
            var matchq = { match_phrase_prefix: {} };
            matchq.match_phrase_prefix['search_item_name'] = txtUserSearch;
            filters.push(matchq);
        }
        const body = await client.search({
            index: elasticLink() + '-autocomplete-loose',
            from: 0, size: 500,
            body: {
                query:
                {
                    bool: {
                        filter:
                        {
                            bool:
                            {
                                must: filters
                            }
                        }
                    }
                },
                sort: [
                    {
                        category_level1_id: "asc"
                    },
                    {
                        "category_level2_name.keyword": "asc"
                    },
                    {
                        "category_level3_name.keyword": "asc"
                    }
                ],
                "aggs": {
                    "category_level1": {
                        terms: {
                            field: "category_level1_id",
                            order: {
                                "_term": "asc"
                            }
                        },
                        "aggs": {
                            "category_level1_name": {
                                terms: {
                                    field: "category_level1_name.keyword"
                                },
                                "aggs": {
                                    "category_level2": {
                                        terms: {
                                            field: "category_level2_id"
                                        },
                                        "aggs": {
                                            "category_level2_name": {
                                                terms: {
                                                    field: "category_level2_name.keyword"
                                                },
                                                "aggs": {
                                                    "category_level3": {
                                                        terms: {
                                                            field: "category_level3_id"
                                                        },
                                                        "aggs": {
                                                            "category_level3_name": {
                                                                terms: {
                                                                    field: "category_level3_name.keyword"
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        );
        var categoryLevel1Array = [];
        var categoryLevel2Array = [];
        var categoryLevel3Array = [];
        var categoryArray1 = [];
        var categoryArray2 = [];
        var categoryArray3 = [];
        for (const category_level1Id of body.aggregations.category_level1.buckets) {
            categoryLevel2Array = [];
            for (const category_level2Id of category_level1Id.category_level1_name.buckets[0].category_level2.buckets) {
                categoryLevel3Array = [];
                if (category_level2Id.category_level2_name.buckets[0].category_level3.buckets[0]) {
                    for (const category_level3Id of category_level2Id.category_level2_name.buckets[0].category_level3.buckets) {
                        if (category_level3Id.category_level3_name.buckets[0]) {
                            categoryArray3 = {
                                categoryName: category_level3Id.category_level3_name.buckets[0].key,
                                categoryId: category_level3Id.key,
                                categoryCount: 0
                            }
                            const resultObject3 = Object.values(categoryArray3);
                            categoryLevel3Array.push(resultObject3)
                        }
                    }
                }
                categoryArray2 = {
                    categoryName: category_level2Id.category_level2_name.buckets[0].key,
                    categoryId: category_level2Id.key,
                    categoryCount: 0,
                    categoryLevel3: categoryLevel3Array
                }
                const resultObject2 = Object.values(categoryArray2);
                categoryLevel2Array.push(resultObject2)
            }
            categoryArray1 = {
                categoryName: category_level1Id.category_level1_name.buckets[0].key,
                categoryId: category_level1Id.key,
                categoryCount: 0,
                categoryLevel2: categoryLevel2Array
            }
            const resultObject1 = Object.values(categoryArray1);
            categoryLevel1Array.push(resultObject1)
        }
        console.log("yttttttttttttttt",categoryLevel1Array)
        this.setState({
            categoryResult: categoryLevel1Array
        });
    }
    render() {
        return (
            <div className="coverWraper">
                 <Suspense fallback={<div></div>}>
                <Header></Header>
                <div className="contentWrap">
                    <div className="container">
                        <div className="globalSearchResult">
                            <div className="globalSearchHead">Results for : <span>{this.state.searchKey}</span></div>
                            <div className="globalSearchContent">
                            {/* {console.log("****************",this.state.itemData)} */}
                                {this.state.itemData.length > 0 ?
                                    <div>
                                        <div className="globalSearchTitle">
                                            {/* <span>{this.state.mode}</span> */}
                                            <span>Products</span>
                                            </div>
                                        <div className="globalSearchRow">
                                           
                                            {this.state.itemData.map((dataObject, index) => (
                                                <GlobalSearchData dataObject={dataObject} index={index} extraClassName="globalItemContentResult"></GlobalSearchData>
                                            ))}
                                        </div>
                                        {this.state.itemData.length < this.state.totalCount ?
                                            <div className="showMoreSection">
                                                <span className="showCommonBtn" onClick={(e) => this.loadMoreData()}>Show more<img src={arrow} alt=""/></span>
                                            </div>
                                        : ''}
                                    </div>
                                : ''}
                                {this.state.storeData.length > 0 ?
                                    <div>
                                        <div className="globalSearchTitle"><span>Stores</span></div>
                                        <div className="globalSearchRow">
                                            {this.state.storeData.map((dataObject, index) => (
                                                <GlobalSearchStoreData dataObject={dataObject} extraClassName="globalItemContentResult"></GlobalSearchStoreData>
                                            ))}
                                        </div>
                                    </div>
                                : ''}                                
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
                </Suspense>
            </div>
        )
    }
}