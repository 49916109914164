import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import PopularItems from "../groceryDefault/popularItemsNew"
import arowdown from '../images/lokal/arow-down.png'
import $ from "jquery";
import { pageHitAPICall } from '../services';
import { elasticLink, elasticHostURL } from "../config";
const AWS = require('aws-sdk');
const elasticsearch = require("elasticsearch");
AWS.config.region = 'us-west-1';
export default class StoreProfileGrocery extends Component {
    constructor(props) {
        super(props)
        this.state = {
            category: [],
            storeData: []
        }
    }
    componentDidMount() {
        window.scrollTo(0, 0)
        this.getCategory()
        var sellerID = "";
        var moduleID = 12;
        var siteCategoryGroupId = "";

        if (window.sessionStorage.getItem('siteCategoryId') !== "0") {
            siteCategoryGroupId = window.sessionStorage.getItem('siteCategoryId');
        }
        else {
            sellerID = window.sessionStorage.getItem('sellerId')
        }
        pageHitAPICall(sellerID, moduleID, siteCategoryGroupId)
    }

    async getCategory() {
        let storeId = window.sessionStorage.getItem('storeId');
        const client = new elasticsearch.Client({
            host: elasticHostURL(),
            amazonES: {
                region: 'us-west-1',
                 //credentials: new AWS.Credentials(this.state.s3AccessKey, this.state.s3SecretKey)
            }
        });
        const body = await client.search({
            index: elasticLink() + '-autocomplete-loose',
            from: 0, size: 0,
            body: {
                query:
                {
                    bool: {
                        filter:
                        {
                            bool:
                            {
                                must: [{
                                    terms: {
                                        store_id: [storeId]
                                    }
                                }
                                ]
                            }
                        }
                    }
                },
                sort: [
                    {
                        category_level1_id: "asc"
                    },
                    {
                        "category_level2_name.keyword": "asc"
                    },
                    {
                        "category_level3_name.keyword": "asc"
                    }
                ],
                "aggs": {
                    "category_level1": {
                        terms: {
                            field: "category_level1_id",
                            order: {
                                "_term": "asc"
                            }
                        },
                        "aggs": {
                            "category_level1_name": {
                                terms: {
                                    field: "category_level1_name.keyword"

                                },
                                "aggs": {
                                    "category_level1_image": {
                                        terms: {
                                            field: "category_level1_img.keyword"
                                        },
                                        "aggs": {
                                            "category_level2": {
                                                terms: {
                                                    field: "category_level2_id"

                                                },
                                                "aggs": {
                                                    "category_level2_name": {
                                                        terms: {
                                                            field: "category_level2_name.keyword"

                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        });
        var categoryLevel1 = []
        for (const category_level1Id of body.aggregations.category_level1.buckets) {
            var categoryLevel2 = []
            if (null != category_level1Id.category_level1_name.buckets[0].category_level1_image.buckets[0]) {
                for (const category_level2Id of category_level1Id.category_level1_name.buckets[0].category_level1_image.buckets[0].category_level2.buckets) {
                    var category1 = {
                        category1_id: category_level1Id.key,
                        category2_id: category_level2Id.key,
                        category2_name: category_level2Id.category_level2_name.buckets[0].key
                    }
                    categoryLevel2.push(category1)
                }
            }
            var img = ''
            if (null != category_level1Id.category_level1_name.buckets[0].category_level1_image.buckets[0])
                img = category_level1Id.category_level1_name.buckets[0].category_level1_image.buckets[0].key
            var category1 = {
                name: category_level1Id.category_level1_name.buckets[0].key,
                category_level1_id: category_level1Id.key,
                level1ImagePath: img,
                categoryLevel2: categoryLevel2
            }
            categoryLevel1.push(category1)
        }
        {console.log("caycategoryLevel1",categoryLevel1)}
        this.setState({
            category: categoryLevel1
        });
    }
    filterOpen() {
        $("#fliterCover").toggleClass("fliterMenuOpen")
    }
    fliterAccordion(index) {

        $("#accordionList" + index).toggleClass("fliterAccordionOpen");
        $("#accordionName" + index).toggleClass("accordionFliterActive");
    }
    render() {
        return (
            <div className="itemCoverDiv">
                <div className="container">
                    <div className="itemListTop">
                        <div className="tagSection">
                            {this.state.category.slice(0, 8).map((category1, index) => (
                                <Link key={index} to={"/listing/" + category1.category_level1_id + "/0/0//" + category1.name.split(' ').join('')}>
                                    <span className="tag topCategory">{category1.name}</span>
                                </Link>
                            ))}
                        </div>
                    </div>
                    <div className="itemListDiv">
                        <div className="itemFilterSection">
                            <span className="mobileFilter" onClick={this.filterOpen}>Filter <img src={arowdown} alt=""/></span>
                            <div id="fliterCover" className="fliterCoverDiv">
                                <div className="itemFilterTitle">Category</div>
                                {this.state.category.map((categoryObj, index) => (
                                    <div className="fliterList">
                                        <h5 className="filterCategoryName" onClick={(e) => this.fliterAccordion(index)} id={"accordionName" + index}>{categoryObj.name}<img src={arowdown} alt=""/></h5>
                                        <ul id={"accordionList" + index}>
                                            {categoryObj.categoryLevel2.map((category2) => (
                                                <li>
                                                    <Link class="filterListName" to={"/listing/" + category2.category1_id + "/" + category2.category2_id + "/0//" + categoryObj.name + '-' + category2.category2_name.split(' ').join('')}>{category2.category2_name}</Link>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="itemListSection"><PopularItems></PopularItems></div>
                    </div>
                </div>
            </div>
        )
    }
}